import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=dc9bc480&"
import script from "./Notification.vue?vue&type=script&lang=js&"
export * from "./Notification.vue?vue&type=script&lang=js&"
import style0 from "./Notification.vue?vue&type=style&index=0&id=dc9bc480&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Markdown: require('/usr/app/components/markdown/Markdown.vue').default,Picture: require('/usr/app/components/picture/picture.vue').default,Button: require('/usr/app/components/button/Button.vue').default,Modal: require('/usr/app/components/modal/Modal.vue').default})
