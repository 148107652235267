import { render, staticRenderFns } from "./HeroSearch.vue?vue&type=template&id=d8d6f506&"
import script from "./HeroSearch.vue?vue&type=script&lang=js&"
export * from "./HeroSearch.vue?vue&type=script&lang=js&"
import style0 from "./HeroSearch.vue?vue&type=style&index=0&id=d8d6f506&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/usr/app/components/input/Input.vue').default,Button: require('/usr/app/components/button/Button.vue').default,WidgetTextList: require('/usr/app/components/widget/TextList.vue').default,Markdown: require('/usr/app/components/markdown/Markdown.vue').default,WidgetText: require('/usr/app/components/widget/Text.vue').default,WidgetApp: require('/usr/app/components/widget/App.vue').default,WidgetQuickLinks: require('/usr/app/components/widget/QuickLinks.vue').default,Picture: require('/usr/app/components/picture/picture.vue').default})
