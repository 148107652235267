
import lang from "@/mixins/lang.js";
import device from "@/mixins/device.js";
export default {
  name: "Footer",
  mixins: [lang, device],
  props: {
    footer: {
      type: Object,
    },
    global: {
      type: Object,
    },
    menu: {
      type: Array,
    },
    bottomMenu: {
      type: Array,
    },
    isLangActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      appVersion: [],
    };
  },
  mounted() {
    this.appVersion = process.env.NUXT_ENV_BITBUCKET_BUILD_NUMBER;
  },
};
